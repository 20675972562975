<route>
{
  "meta": {
    "permission": [
      "emails.view_maillist"
    ]
  }
}
</route>

<template>
  <v-container fluid>
    <i-table
      :title="$tc('email', 2)"
      :headers="headers"
      app="emails.maillist"
      api="email"
      @click:create="changeRoute({ pk: 'create' })"
      @click:edit="changeRoute"
    >
      <template v-slot:item.body="{ item }">
        <span v-html="item.body"></span>
      </template>
    </i-table>
  </v-container>
</template>
<script>
export default {
  computed: {
    headers() {
      return [
        {
          text: this.$t('addressee'),
          value: 'recipient_list'
        },
        {
          text: this.$t('subject'),
          value: 'subject'
        },
        {
          text: this.$t('body'),
          value: 'body'
        }
      ]
    }
  },
  methods: {
    changeRoute(item) {
      this.$router.push({
        name: 'sendEmail-pk',
        params: { pk: this.codification({ code: item.pk }) }
      })
    }
  }
}
</script>
